import axios from "axios"

function request(type, url, data) {


  let ajaxConfig = {
    method: type,
    url: url,
    data: data,
    proxy: true,
    headers: {
      'Content-Type': 'application/json'
    }

  }
  // console.log(ajaxConfig)
  return axios(ajaxConfig)
}


function open_new_page(url) {

  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  var id = String(new Date().getTime())
  a.setAttribute("id", id);
  // 防止反复添加      
  document.body.appendChild(a);
  a.click();
 
}



export {
  request,
  open_new_page

}